export {}

const topProductsSections = document.querySelectorAll<HTMLElement>(".s-top-products")

topProductsSections.forEach((section) => {
  const groups = section.querySelectorAll<HTMLElement>(".s-top-products__products")
  const tabControl = section.querySelector<HTMLElement>(".s-top-products__tab-controls")
  const button = section.querySelector<HTMLAnchorElement>(".s-top-products__button")

  if (tabControl) {
    tabControl.addEventListener("selected-tab", (e) => {
      const selectedTab = e.target as HTMLElement
      if (button) {
        const url = selectedTab.dataset.url
        const title = selectedTab.dataset.title

        button.classList.remove("--hidden")
        button.href = url ?? ""
        const titleWrapper = button.querySelector<HTMLElement>(".btn__title")
        if (titleWrapper) titleWrapper.innerHTML = title ?? ""

        if (!url || !title) {
          button.classList.add("--hidden")
        }
      }
      groups.forEach((group) => {
        group.classList.toggle("--hidden", !(selectedTab.dataset.id && group.dataset.groupId?.includes(selectedTab.dataset.id)))
      })
    })
  }
})
