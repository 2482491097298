/**
 * @example import {addGlobalEventListener} from "./utils"
 * addGlobalEventListener("click", ".i-usp", (clickedElement) => {
 *  console.log(clickedElement)
 * })
 */
export function addGlobalEventListener(type: string, selector: string, callback: (clickedElement: Element, event: Event) => void, parent: Document | Element = document) {
  parent.addEventListener(type, (event) => {
    const target = event.target

    if (target instanceof Element && target.closest(selector)) {
      const clickedElement = target.closest(selector)
      if (clickedElement) {
        callback(clickedElement, event)
      }
    }
  })
}

export function hashCode(str: string) {
  return str.split("").reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
}
