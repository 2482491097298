export {}

const tabControlsGroups = document.querySelectorAll<HTMLElement>(".g-tab-controls")

tabControlsGroups.forEach((group) => {
  const items = group.querySelectorAll<HTMLElement>(".i-tab-control")

  items.forEach((item) => {
    item.addEventListener("click", (e) => {
      e.preventDefault()
      items.forEach((item) => item.classList.remove("--selected"))
      item.classList.add("--selected")
      item.dispatchEvent(new Event("selected-tab", {bubbles: true}))
    })
  })

  items[0]?.dispatchEvent(new Event("click"))
})
