export {}

const mobileQuery = window.matchMedia("(max-width: 61.999em)")

const itemsWithSubmenu = document.querySelectorAll(".i-menu.--has-submenu")
itemsWithSubmenu.forEach((item) => {
  const submenuButton = item.querySelector(".i-menu__link:not(.--submenu-mobile-only) .i-menu__submenu-button")
  const closeButton = item.querySelector(".i-menu__submenu-close")
  const handleSubmenu = (event: Event) => {
    if (!mobileQuery.matches) return // its desktop, so we don't care
    event.preventDefault()
    item.classList.toggle("--open")
  }

  let hoverTimeout: number | null = null

  item.addEventListener("mouseenter", () => {
    hoverTimeout = window.setTimeout(() => {
      item.classList.add("hover")
    }, 200)
  })

  item.addEventListener("mouseleave", () => {
    if (hoverTimeout !== null) {
      clearTimeout(hoverTimeout)
      hoverTimeout = null
    }

    item.classList.remove("hover")
  })

  submenuButton?.addEventListener("click", handleSubmenu)
  closeButton?.addEventListener("click", handleSubmenu)
})
