import {hashCode} from "js/utils"

const localStorageNotificationHash = localStorage.getItem("notificationHash")

const notifications = document.querySelectorAll(".i-notification")

notifications.forEach((notification) => {
  const notificationText = notification.querySelector(".i-alert__content")?.textContent
  const notificationHash = notificationText ? String(hashCode(notificationText)) : null

  if (localStorageNotificationHash && notificationHash === localStorageNotificationHash) {
    notification.remove()
    return
  }

  const closeButtons = notification.querySelectorAll(".i-notification__close")

  closeButtons.forEach((closeButton) => {
    closeButton.addEventListener("click", () => {
      notification.remove()
      notificationHash && localStorage.setItem("notificationHash", notificationHash)
    })
  })
})
