const init = () => {
  const buttons = document.querySelectorAll<HTMLElement>(".js-watchdog-add:not(.--initialized)")
  buttons.forEach((button) => {
    const productId = button.dataset.productId
    button.classList.add("--initialized")
    if (!productId) return

    button.addEventListener("click", () => {
      const productIdInput = document.querySelector<HTMLInputElement>(".f-watchdog__product-id-input")

      if (productIdInput) productIdInput.value = productId
    })
  })
}
init()
document.addEventListener("reinitWatchdogAddButtons", init)
