import {makeRequest} from "js/request-api/request-api"

export type Snippets = {
  redrawSnippet?: Snippet[]
  appendSnippet?: Snippet[]
  flashMessages?: Snippet[]
  removeSnippet?: Snippet[]
  addressUrl?: string
  openModalId?: string
  closeModalId?: string
}

type Snippet = {
  snippetId: string
  body: string
}

const flashMessagesSnippets = (snippets: Snippets) => {
  snippets.flashMessages?.forEach((snippet) => {
    const alertsGroup = document.querySelector(".g-alerts__list")
    alertsGroup?.insertAdjacentHTML("beforeend", snippet.body)
  })
  document.dispatchEvent(new Event("alertsReinit"))
}

const redrawSnippets = (snippets: Snippets) => {
  snippets.redrawSnippet?.forEach((snippet) => {
    const target = document.querySelector<HTMLElement>(`[id="${snippet.snippetId}"], .${snippet.snippetId}`)

    if (target) {
      target.innerHTML = snippet.body
      target.dispatchEvent(new Event("redraw"))
    }
  })
}

const appendSnippets = (snippets: Snippets) => {
  snippets.appendSnippet?.forEach((snippet) => {
    const target = document.querySelector<HTMLElement>(`[id="${snippet.snippetId}"], .${snippet.snippetId}`)

    if (target) {
      /**
       * Dirty quick hack for resolving issue that dataLayer (GTM) was not updated during ajax calls.
       * The reason is simple, by the official HTML5 spec: script elements inserted using innerHTML do not execute
       * when they are inserted. Inserting the adjacent HTML is the same as modifying innerHTML of a specific
       * DOM element.
       */
      if ('data-layer' === snippet.snippetId) {
        const appendScript = document.createElement('script');
        appendScript.innerHTML = snippet.body;
        document.body.appendChild(appendScript);
      } else {
        target.insertAdjacentHTML("beforeend", snippet.body)
      }
      target.dispatchEvent(new Event("append"))
    }
  })
}

const removeSnippets = (snippets: Snippets) => {
  snippets.removeSnippet?.forEach((snippet) => {
    const target = document.querySelector<HTMLElement>(`[data-delete-target="${snippet.snippetId}"]`)
    target?.dispatchEvent(new Event("remove"))

    target?.remove()
  })
}

const addressUrlHandle = (snippets: Snippets) => {
  if (snippets.addressUrl) {
    window.history.pushState({}, "", `${window.location.origin}/${snippets.addressUrl}`)
  }
}

const openModalHandle = (snippets: Snippets) => {
  if (snippets.openModalId) {
    const modal = document.getElementById(snippets.openModalId)
    if (modal) modal.dispatchEvent(new Event("open"))
  }
}

const closeModalHandle = (snippets: Snippets) => {
  if (snippets.closeModalId) {
    const modal = document.getElementById(snippets.closeModalId)
    if (modal) modal.dispatchEvent(new Event("close"))
  }
}

export const handleSnippets = (snippets: Snippets) => {
  removeSnippets(snippets)
  redrawSnippets(snippets)
  appendSnippets(snippets)
  addressUrlHandle(snippets)
  openModalHandle(snippets)
  closeModalHandle(snippets)
  flashMessagesSnippets(snippets)

  if (snippets.redrawSnippet || snippets.appendSnippet) {
    window.lazyloading?.update()
    document.dispatchEvent(new Event("overlayscrollbarsReinit"))
    document.dispatchEvent(new Event("sameHeight"))
  }
}

export const getSnippets = async (url: string) => {
  const responseSettings = {
    method: "GET",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  }
  const response = await makeRequest(url, responseSettings)

  if (!response.ok) return null

  // this returns string for some reason, hopefuly BE will fix it
  const json = await response.json()
  const data: Snippets = JSON.parse(json)
  // const data: Snippets = json

  console.log(data)

  handleSnippets(data)
}

const postSnippets = async (url: string, body: FormData) => {
  const responseSettings = {
    method: "POST",
    body: body,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  }

  console.log(Object.fromEntries(body))

  const response = await makeRequest(url, responseSettings)

  if (!response.ok) return null

  // this returns string for some reason, hopefuly BE will fix it
  const json = await response.json()
  const data: Snippets = JSON.parse(json)
  // const data: Snippets = json

  console.log(data)

  handleSnippets(data)
}

const events = ["click", "change"] as const

const handleEvent = async (event: Event, eventName: "click" | "change") => {
  if (!(event.target instanceof Element) || !event.target?.closest(".js-fetch")) return

  const element = event.target.closest(".js-fetch")

  if (!element) return

  if (element.hasAttribute("disabled")) return

  // if it's a label, and there is a checbox inside, its trigger event twice. But "change" event will job done
  if (element instanceof HTMLLabelElement && eventName === "click") return

  const url = element.getAttribute("href") ?? element.getAttribute("data-url") ?? null
  const urlSuffix = element.getAttribute("data-url-suffix") ?? ""

  if (!url) return

  if (element instanceof HTMLAnchorElement) event.preventDefault()

  callGetSnippets(element, `${url}${urlSuffix}`)
}

export const callGetSnippets = async (element: Element, url: string) => {
  beforeRequest(element)
  await getSnippets(url)
  afterRequest(element)
}

events.forEach((eventName) => {
  document.addEventListener(eventName, async (event) => {
    await handleEvent(event, eventName)
  })
})

export const handlePostEvent = async (form: HTMLFormElement, event?: Event) => {
  event?.preventDefault()

  const formData = new FormData(form)
  const action = form.getAttribute("action")?.trim()
  const url = action || window.location.href

  if (!form.checkValidity()) return

  beforeRequest(form)
  await postSnippets(url, formData)
  afterRequest(form)
}

document.querySelectorAll<HTMLFormElement>("form.js-post").forEach((form) => {
  form.addEventListener("submit", async (event) => {
    handlePostEvent(form, event)
  })
})

const beforeRequest = (element: Element) => {
  const loadingTarget = document.querySelector<HTMLElement>(`.${element.getAttribute("data-loading-target")}`)
  element.dispatchEvent(new Event("beforeFetch"))
  loadingTarget?.classList.add("--loading")
}
const afterRequest = (element: Element) => {
  const loadingTarget = document.querySelector<HTMLElement>(`.${element.getAttribute("data-loading-target")}`)
  const scrollToTarget = document.querySelector<HTMLElement>(`.${element.getAttribute("data-scroll-target")}`)

  element.dispatchEvent(new Event("afterFetch"))
  loadingTarget?.classList.remove("--loading")

  if (scrollToTarget) {
    scrollToTarget.style.scrollMarginTop = `${(document.querySelector(".m-header")?.clientHeight ?? 0) * 2}px`
    scrollToTarget.scrollIntoView({behavior: "instant", block: "start"})
    scrollToTarget.style.removeProperty("scroll-margin-top")
  }
}
